import React from "react";
import TextLoop from "react-text-loop";
import { Link } from "react-router-dom";
import { Container } from "react-grid-system";

function Intro() {
    return (
        <section className="section section-hero section-hero-5 overlay-image" style={{  }}>
            <div className="display-center">
                <Container>
                    <div className="el-heading">
                        <h1>
                            I'm a{" "}
                            <TextLoop>
                                <span>Coding Lover</span>
                                <span>Passionate Mobile Developer</span>
                                <span>Software Craftsman with Agile mindset</span>
                            </TextLoop>
                            <br />
                            I make life easier by building Mobile Applications.
                            <br />
                            Based in Helsinki, Finland.
                        </h1>
                        <div className="el-icon">
                            <p className="el-icon-title">
                                <Link to={"//about"}>
                                    About Me <span className="icon ti-arrow-down" />
                                </Link>
                            </p>
                        </div>
                    </div>
                </Container>
            </div>
        </section>
    );
}

export default Intro;
