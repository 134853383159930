import React from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";

function Contact() {
    return (
        <section className="section section-contact section-contact-1">
            <div className="display-spacing">
                <Container>
                    <Row>
                        <Col lg={7}>
                            <div className="contact-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title">CONTACT ME</span>
                                    </h2>
                                    <h3>
                                        <span>I'm here to help.</span>
                                    </h3>
                                    <div className="divider divider-1"></div>
                                    <p>If you have any questions, feedback, or need support, please don’t hesitate to reach out to me. Your input is valuable to me, and I'm committed to providing the best possible experience.</p>
                                    <h3>
                                        <span>Contact Information.</span>
                                    </h3>
                                    <div className="divider divider-1"></div>
                                    <ul>
                                        <li>Email: <a href="mailto:ngoc@coder.life">ngoc@coder.life</a></li>
                                    </ul>
                                </header>
                                
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Contact;
