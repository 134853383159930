import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, useLocation } from "react-router-dom";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import * as serviceWorker from "./serviceWorker";
// Stylesheet
import "./index.scss";
// Scenes

import Home5 from "./scenes/Home5";
// Components
import Error404 from "./components/common/Error404";

const firebaseConfig = {
    apiKey: "AIzaSyD0yuwcPKmTeqGCbamcWf8qt9fTpbZcqqU",
    authDomain: "coderlifeweb.firebaseapp.com",
    projectId: "coderlifeweb",
    storageBucket: "coderlifeweb.appspot.com",
    messagingSenderId: "623919937051",
    appId: "1:623919937051:web:be76ad91f889b828d82abe",
    measurementId: "G-TZR15LQJWH"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

function FirebaseAnalytics() {
    const location = useLocation();
    useEffect(() => {
        if (typeof analytics === "function") {
            const page_path = location.pathname;
            analytics().setCurrentScreen(page_path);
            analytics().logEvent("page_view", { page_path });
        }
    }, [location]);
    return null;
}

const Root = () => {
    return (
        <div>
            <Home5 />
        </div>
    );
};

ReactDOM.render(
    <HashRouter>
        <div>
            <Route render={({ location }) => (location.state && location.state.is404 ? <Error404 /> : <Root />)} />
            <FirebaseAnalytics />
        </div>
    </HashRouter>,
    document.getElementById("root")
);
serviceWorker.unregister();
