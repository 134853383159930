import React from "react";
import { Container, Row, Col } from "react-grid-system";
// Components
import ProgressBar from "../../components/common/ProgressBar";

const skills = [
    {
        title: "iOS Native",
        percent: 100,
    },
    {
        title: "Android Native",
        percent: 90,
    },
    {
        title: "React Native",
        percent: 80,
    },
    {
        title: "Flutter",
        percent: 60,
    },
    {
        title: "Agile Methodology",
        percent: 100,
    },
    {
        title: "CI/CD",
        percent: 100,
    },
];
const education = [
    {
        title: "BSc Software Engineering",
        subtitle: "Ho Chi Minh City University of Science",
        description: "",
        date: "(2006-2010)",
        icon: "ti-crown",
    },
    {
        title: "Master's Degree Programmes in Information Technology ",
        subtitle: "Tampere University of Applied Sciences",
        description: "",
        date: "(2017-2019)",
        icon: "ti-cup",
    }
];
const experience = [
    {
        title: "Senior Mobile Developer",
        subtitle: "Tibber | Helsinki, Finland",
        description: "Skills: Swift/iOS, Kotlin/Android, CI/CD, GitHub Action, CircleCI, Project Management, Analytical Skills, Coaching, Testing Practices, A/B Testing.",
        date: "(2022-2023)",
        icon: "ti-pencil-alt",
    },
    {
        title: "Senior Mobile Developer",
        subtitle: "Vincit | Helsinki, Finland",
        description: "Skills: Objective-C, Swift, Kotlin/Android, C++, PJSIP, Docker, GitLab CI, C++, Bluetooth Low Energy, Nordic Semi BLE, Nordic Semi DFU, Typescript, React Native, Redux.",
        date: "(2020-2022)",
        icon: "ti-pencil-alt",
    },
    {
        title: "Senior Mobile Developer",
        subtitle: "Maas Global | Helsinki, Finland",
        description: "Skills: Swift, RxSwift, REST API, Rx-MVVM, XCTest, Quick, Nimble, Fastlane, Travis CI, Firebase.",
        date: "(2019-2019)",
        icon: "ti-pencil-alt",
    },
    {
        title: "Senior Mobile Developer",
        subtitle: "Smarp | Helsinki, Finland",
        description: "Skills: Swift (iOS), Java (Android), XCTest, Mockitor / JUnit, Espresso, REST API, MVVM, VIPER, Fastlane, Git, Gitlab CI, Firebase, Google Analytics, MixPanel.",
        date: "(2017-2019)",
        icon: "ti-pencil-alt",
    },
    {
        title: "Lead Mobile Developer",
        subtitle: "Dirox | Ho Chi Minh City, Vietnam",
        description: "Skills: Objective-C (iOS), Java (Android), C++ (Cocos2dX), JNI, Python, Git, Firebase, Google Analytics, AppFlyer, MixPanel.",
        date: "(2014-2016)",
        icon: "ti-heart",
    },
    {
        title: "Mobile Developer",
        subtitle: "Dirox | Ho Chi Minh City, Vietnam",
        description: "Skills: Objective-C (iOS), Java (Android), C# (Unity3D), Git",
        date: "(2011-2014)",
        icon: "ti-paint-bucket",
    },
];

function Resume() {
    return (
        <section className="section section-resume section-resume-1">
            <div className="display-spacing">
                <header className="el-heading el-heading-center">
                    <div className="el-icon">
                        <h2 className="el-icon-title">
                            <span>RESUME</span>
                        </h2>
                    </div>
                    <h3>Education & Experience</h3>
                    <div className="divider divider-1-reverse divider-1-1"></div>
                    <div className="divider divider-1-reverse divider-1-2"></div>
                </header>
                <Container className="mb-spacing">
                    <Row>
                        <Col md={6}>
                            <div className="resume-row">
                                <h4 className="resume-title">Education</h4>
                                <ul>
                                    {education.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>{item.subtitle}</h6>
                                                </div>
                                                <div className="resume-body">
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="resume-row">
                                <h4 className="resume-title">Experience</h4>
                                <ul>
                                    {experience.map((item, index) => (
                                        <li key={index}>
                                            <div className="resume-item">
                                                <div className="resume-head">
                                                    <div className="el-icon">
                                                        <span className={`el-icon-icon ${item.icon}`}></span>
                                                    </div>
                                                    <h5>{item.title}</h5>
                                                    <h6>
                                                        {item.subtitle} <small>{item.date}</small>
                                                    </h6>
                                                </div>
                                                <div className="resume-body">
                                                    <p>{item.description}</p>
                                                </div>
                                            </div>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        {skills.map((item, index) => (
                            <Col key={index} sm={6} xl={4}>
                                <div className="skills-item">
                                    <ProgressBar label={item.title} percent={item.percent} />
                                </div>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </section>
    );
}

export default Resume;
