import React, { useState } from "react";
import { useRouteMatch, NavLink, Link } from "react-router-dom";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";

const links = [
    {
        title: "Home",
        path: "/intro",
    },
    {
        title: "About",
        path: "/about",
    },
    {
        title: "Resume",
        path: "/resume",
    },
    // {
    //     title: "Service",
    //     path: "/service",
    // },
    {
        title: "Blog",
        path: "/blog",
    },
    {
        title: "KieliPro",
        path: "/kieli-pro",
    },
    {
        title: "Contact",
        path: "/contact",
    },
];

function Header() {
    let { url } = useRouteMatch();

    const [displaySidenav, setDisplaySidenav] = useState(false);

    const handleClick = (e) => {
        e.preventDefault();
        setDisplaySidenav(!displaySidenav);
    };

    return (
        <>
            <header className="app-header app-header-1">
                <div className="header-logo">
                    <Link to={"//intro"}>
                        <img src="/assets/images/logo_dark.png" alt="logo" />
                    </Link>
                </div>
                <button className="button button-menu" onClick={(e) => handleClick(e)}>
                    <span></span>
                </button>
                <ul className="header-dots">
                    {links.filter((item) => item.path !== "/blog").map((item, index) => (
                        <li key={index}>
                            <NavLink to={`${url}${item.path}`} activeClassName="active">
                                <span className="circle"></span>
                            </NavLink>
                        </li>
                    ))}
                </ul>
                <ul className="header-menu">
                    {links.map((item, index) => (
                        <li key={index}>
                            { item.path !== "/blog" ?
                                <NavLink to={`${url}${item.path}`} activeClassName="active">
                                    <span className="anchor">{item.title}</span>
                                </NavLink>
                            :
                                <a href="https://blog.coder.life/">
                                    <span className="anchor">{item.title}</span>
                                </a>
                            }
                        </li>
                    ))}
                </ul>
                <div className="header-follow">
                    <ul>
                        <li>
                            <a href="https://github.com/ngoclt">
                                <i className="ti-github"></i>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/in/letuanngoc">
                                <i className="ti-linkedin"></i>
                            </a>
                        </li>
                    </ul>
                    <p>Follow Me</p>
                </div>
            </header>
            <div className={`app-sidenav ${displaySidenav && "active"}`}>
                <div className="sidenav-menu">
                    <div className="logo">
                        <Link to={"//intro"}>coder.life</Link>
                    </div>
                    <button className="button" onClick={(e) => handleClick(e)}></button>
                    <ul className="sidenav-nav">
                        {links.map((item, index) => (
                            item.path !== "/blog" ?
                                <li key={index} onClick={(e) => handleClick(e)}>
                                    <NavLink to={`${url}${item.path}`.replace(/([^:])(\/\/+)/g, "$1/")} activeClassName="active">
                                        <span className="anchor">{item.title}</span>
                                    </NavLink>
                                </li>
                                    :
                                <li key={index}>
                                    <a href="https://blog.coder.life/">
                                        <span className="anchor">{item.title}</span>
                                    </a>
                                </li>
                        ))}
                    </ul>
                    <div className="sidenav-links">
                        <ul>
                            <li>
                                <a href="https://github.com/ngoclt">
                                    <FaGithub className="icon" />
                                </a>
                            </li>
                            <li>
                                <a href="https://www.linkedin.com/in/letuanngoc">
                                    <FaLinkedinIn className="icon" />
                                </a>
                            </li>
                        </ul>
                        <p>Follow Me</p>
                    </div>
                </div>
                <span className="sidenav-close" />
            </div>
        </>
    );
}

export default Header;
